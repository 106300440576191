import {
  ExternalLinkSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
} from '@motion/icons'
import { type ProjectSchema } from '@motion/rpc-types'
import { type ActionItem } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type ETA_SOURCE } from '~/@types/analytics'
import { useCancelProject, useCompleteProject } from '~/areas/project/hooks'
import { useWorkspaceFns } from '~/global/hooks'
import { useNavigateByGlobalModalId } from '~/global/navigation'
import { useMemo } from 'react'

import { type ActionOptions } from './types'
import { useExtendedNoneEta } from './use-extended-none-eta'

export const useProjectActions = (
  project: ProjectSchema,
  actionList: ActionOptions[] = [],
  onClose: () => void,
  source: ETA_SOURCE
) => {
  const completeProject = useCompleteProject()
  const cancelProject = useCancelProject()
  const { getWorkspaceCanceledStatus } = useWorkspaceFns()
  const navigateToModal = useNavigateByGlobalModalId()
  const deadlineStatus = useExtendedNoneEta({
    entity: project,
    type: 'project',
  })

  return useMemo<ActionItem[]>(() => {
    return actionList
      .map((action) => {
        switch (action) {
          case 'complete':
            return {
              content: 'Complete project',
              prefix: <StatusCompletedSolid />,
              onAction: () => {
                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'complete',
                  type: 'project',
                  source,
                  eta: deadlineStatus,
                })
                completeProject(project.id)
                onClose()
              },
            }
          case 'cancel':
            return {
              content: 'Cancel project',
              prefix: (
                <StatusCanceledSolid className='text-dropdown-item-icon-default' />
              ),
              onAction: () => {
                const canceledStatus = getWorkspaceCanceledStatus(
                  project.workspaceId
                )
                if (!canceledStatus) {
                  throw new Error('Canceled status not found')
                }

                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'cancel',
                  type: 'project',
                  source,
                  eta: deadlineStatus,
                })

                cancelProject(project.id, canceledStatus.id)
                onClose()
              },
            }
          case 'edit':
            return {
              content: 'Go to project',
              prefix: <ExternalLinkSolid />,
              onAction: () => {
                recordAnalyticsEvent('ETA_ACTION', {
                  action: 'view',
                  type: 'project',
                  source,
                  eta: deadlineStatus,
                })
                navigateToModal('project', { project: project.id })
                onClose()
              },
            }
          default:
            return null
        }
      })
      .filter(Boolean) as ActionItem[]
  }, [
    actionList,
    cancelProject,
    completeProject,
    deadlineStatus,
    getWorkspaceCanceledStatus,
    navigateToModal,
    onClose,
    project.id,
    project.workspaceId,
    source,
  ])
}
