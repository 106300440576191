import { type TaskSchema } from '@motion/zod/client'

import { useParentChunkTask } from '~/areas/tasks/hooks'
import { type TaskByIdApi, useTaskByIdV2 } from '~/global/rpc/v2'

export type UseTaskWithParentProps = {
  taskId: TaskSchema['id'] | null | undefined
}

export function useTaskWithParent(
  { taskId }: UseTaskWithParentProps,
  opts?: TaskByIdApi['UseQueryOptions']
) {
  const { data: task, isInitialLoading } = useTaskByIdV2(
    {
      id: taskId,
    },
    {
      enabled: taskId != null,
      keepPreviousData: false,
      ...opts,
    }
  )

  // If the task is a chunk, it will always be a chunk parent
  const { data: parentTask, isLoading: isLoadingParentTask } =
    useParentChunkTask(task, {
      keepPreviousData: false,
      ...opts,
    })

  const parentOrDirectTask = parentTask ?? task
  const isLoading = isInitialLoading || isLoadingParentTask

  return {
    task,
    parentOrDirectTask,
    isLoading: taskId != null && isLoading,
    hasError: !isLoading && taskId != null && task == null,
    parentTask:
      parentTask?.id !== parentOrDirectTask?.id ? parentTask : undefined,
  }
}
