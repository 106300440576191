import { ConnectedProjectEtaBadge } from '~/areas/eta'
import { StageBadge } from '~/global/components/badges'
import { useStageDefinition } from '~/global/hooks'
import { type ProjectWithRelations } from '~/global/proxies'

type ProjectItemInfoProps = {
  project: ProjectWithRelations
}

export const ProjectItemInfo = ({ project }: ProjectItemInfoProps) => {
  const stageDefinition = useStageDefinition(project.activeStageDefinitionId)

  return (
    <div className='flex items-center gap-1'>
      {project.activeStageDefinitionId && stageDefinition && (
        <StageBadge value={stageDefinition} size='small' />
      )}
      <ConnectedProjectEtaBadge project={project} size='small' source='gantt' />
    </div>
  )
}
