import { type NormalTaskSchema } from '@motion/zod/client'

import { useCachedItem } from '~/global/cache'
import { type TaskByIdApi } from '~/global/rpc/v2'

import { useTaskWithParent } from '../use-task-with-parent'

type Params = {
  taskId: string | undefined
}

type ParsedData = {
  blockedBy: NormalTaskSchema[]
  blocking: NormalTaskSchema[]
}
type Value = ParsedData & { isLoading: boolean }

export const useBlockerTasks = (
  params: Params,
  opts?: TaskByIdApi['UseQueryOptions']
): Value => {
  const { parentOrDirectTask: task, isLoading } = useTaskWithParent(
    params,
    opts
  )

  const blockedByTasks = useCachedItem(
    'tasks',
    task?.type === 'NORMAL' ? task.blockedByTaskIds : []
  ) as NormalTaskSchema[]
  const blockingTasks = useCachedItem(
    'tasks',
    task?.type === 'NORMAL' ? task.blockingTaskIds : []
  ) as NormalTaskSchema[]

  return { isLoading, blockedBy: blockedByTasks, blocking: blockingTasks }
}
