import { type StageSchema } from '@motion/rpc-types'
import { Button, PopoverTrigger } from '@motion/ui/base'
import { getEtaLabel } from '@motion/ui-logic'

import { StageEtaPopover } from '~/areas/eta/components/popover'
import { useProjectStagesWithDefinitions } from '~/areas/project/hooks/data'
import { StageLabel } from '~/global/components/labels'
import { type ReactNode } from 'react'

type MultipleStagesNeedAttentionDropdownProps = Omit<
  MultipleStagesNeedAttentionDropdownContentProps,
  'close'
> & {
  children: ReactNode
}

export function MultipleStagesNeedAttentionDropdown({
  children,
  ...rest
}: MultipleStagesNeedAttentionDropdownProps) {
  return (
    <PopoverTrigger
      placement='bottom-end'
      renderPopover={() => (
        <MultipleStagesNeedAttentionDropdownContent {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

type MultipleStagesNeedAttentionDropdownContentProps = {
  stagesThatNeedAttention: StageSchema[]
}

export function MultipleStagesNeedAttentionDropdownContent({
  stagesThatNeedAttention,
}: MultipleStagesNeedAttentionDropdownContentProps) {
  const stagesWithDefinitions = useProjectStagesWithDefinitions(
    {
      stages: stagesThatNeedAttention,
    },
    { includeCancelledStages: false }
  )

  return (
    <div className='p-1 min-w-[240px]'>
      <div className='px-2 pt-2 pb-0.5 text-semantic-neutral-text-subtle text-xs'>
        {stagesWithDefinitions.length} stages need attention
      </div>
      <div>
        {stagesWithDefinitions.map((stage) => (
          <div
            key={stage.id}
            className='flex flex-row gap-2 py-1.5 px-2 self-stretch items-center justify-between'
          >
            <div className='flex flex-col gap-1'>
              <StageLabel value={stage.stageDefinition} />
              <span className='text-xs text-semantic-neutral-text-subtle'>
                {getEtaLabel(stage.deadlineStatus) ?? ''}
              </span>
            </div>
            <div>
              <StageEtaPopover entity={stage} source='modal-banner'>
                <Button size='xsmall' sentiment='neutral' variant='outlined'>
                  Resolve
                </Button>
              </StageEtaPopover>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
