import { CalendarSolid } from '@motion/icons'
import { type TaskSchema } from '@motion/rpc-types'
import { ActionList, Button, PopoverTrigger } from '@motion/ui/base'
import {
  type ETADateOptions,
  getEtaDateOptions,
  normalizeTaskDeadlineStatus,
} from '@motion/ui-logic'
import { safeParseDate } from '@motion/utils/dates'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { TaskDeadlineDropdown } from '~/areas/tasks/components'
import { useTaskDeadlineUpdater } from '~/areas/tasks/hooks'
import { type TaskWithRelations } from '~/global/proxies'
import { useMemo, useState } from 'react'

import { EtaBanner } from './banner'
import { SHOW_DATE_OPTION_STATUSES } from './constants'
import {
  PopoverContainer,
  PopoverExtendDeadlineContainer,
  PopoverExtendDeadlineText,
  SectionContainer,
} from './styled'
import {
  type CommonPopoverContentProps,
  type CommonPopoverProps,
} from './types'

import { type ActionOptions, useSingleTaskActions } from '../../hooks'
import { ProjectedDateOptions } from '../common'

type TaskEtaPopoverContentProps = {
  task: TaskWithRelations | TaskSchema
} & CommonPopoverContentProps

export const TaskEtaPopover = ({
  entity,
  children,
  config = {},
  source,
}: CommonPopoverProps<TaskWithRelations | TaskSchema>) => {
  const deadlineStatus = normalizeTaskDeadlineStatus(entity)

  return (
    <PopoverTrigger
      renderPopover={({ close }) => {
        if (deadlineStatus === 'none') return null
        return (
          <TaskEtaPopoverContent
            task={entity}
            onClose={close}
            config={config}
            source={source}
          />
        )
      }}
    >
      {children}
    </PopoverTrigger>
  )
}

export const TaskEtaPopoverContent = (props: TaskEtaPopoverContentProps) => {
  const { task, onClose, config = { hideEditButton: false }, source } = props

  const [selectedOption, setSelectedOption] =
    useState<ETADateOptions>('projectedDate')

  const deadlineStatus = normalizeTaskDeadlineStatus(task)
  const updateTaskDeadline = useTaskDeadlineUpdater()

  const actions = useMemo<ActionOptions[]>(() => {
    let options: ActionOptions[] = []

    switch (deadlineStatus) {
      case 'none':
        options = []
        break
      case 'missed-deadline':
        options = ['complete', 'cancel', 'edit']
        break
      case 'scheduled-past-deadline':
        options = ['hardDeadline', 'doASAP', 'complete', 'cancel', 'edit']
        break
      default:
        options = []
        break
    }

    if (config.hideEditButton) {
      options = options.filter((option) => option !== 'edit')
    }

    return options
  }, [config.hideEditButton, deadlineStatus])

  const coreActions = useSingleTaskActions(task, actions, onClose, source)

  const projectedDate =
    task.type === 'NORMAL' && task.estimatedCompletionTime
      ? task.estimatedCompletionTime
      : (task.scheduledEnd ?? null)

  const dateOptions = useMemo(() => {
    if (!projectedDate) return null

    return getEtaDateOptions(projectedDate)
  }, [projectedDate])

  const updateDateToSelectedOption = () => {
    if (!dateOptions || !dateOptions[selectedOption]) return

    const optionDate = dateOptions[selectedOption]
    const parsedDate = safeParseDate(optionDate)
    if (!parsedDate) return

    recordAnalyticsEvent('ETA_POPOVER_PROJECTED_DEADLINE_BUTTON_EXTEND', {
      source,
      option: selectedOption,
      type: 'task',
      eta: deadlineStatus,
    })

    updateTaskDeadline(task.id, parsedDate.toISO())
    onClose()
  }

  const onChooseOption = (option: ETADateOptions) => {
    recordAnalyticsEvent('ETA_POPOVER_PROJECTED_DEADLINE_BUTTON_CLICK', {
      option,
      source,
      type: 'task',
      eta: deadlineStatus,
    })

    setSelectedOption(option)
  }

  return (
    <PopoverContainer>
      <EtaBanner entity={task} type='task' />
      {projectedDate &&
        dateOptions &&
        SHOW_DATE_OPTION_STATUSES.includes(deadlineStatus) && (
          <div className='p-3 flex flex-col gap-3'>
            <TaskExtendDeadlineRow {...props} />
            <ProjectedDateOptions
              options={dateOptions}
              selectedOption={selectedOption}
              onChooseOption={onChooseOption}
            />
            <div className='flex justify-end'>
              <Button
                sentiment='primary'
                variant='solid'
                size='small'
                onClick={updateDateToSelectedOption}
              >
                Extend deadline
              </Button>
            </div>
          </div>
        )}
      {(deadlineStatus === 'missed-deadline' ||
        deadlineStatus === 'scheduled-past-deadline') && (
        <SectionContainer>
          <ActionList items={coreActions} />
        </SectionContainer>
      )}
    </PopoverContainer>
  )
}

const TaskExtendDeadlineRow = ({
  task,
  onClose,
}: TaskEtaPopoverContentProps) => {
  const updateTaskDeadline = useTaskDeadlineUpdater()

  const changeDeadline = (value: string) => {
    updateTaskDeadline(task.id, value)
    onClose()
  }

  return (
    <div className='flex items-center justify-between'>
      <PopoverExtendDeadlineContainer>
        <CalendarSolid className='size-4' />
        <PopoverExtendDeadlineText>Extend deadline</PopoverExtendDeadlineText>
      </PopoverExtendDeadlineContainer>
      <TaskDeadlineDropdown
        value={task.dueDate}
        onChange={changeDeadline}
        contextProps={{ taskId: task.id }}
      >
        <Button sentiment='primary' variant='link'>
          Choose date
        </Button>
      </TaskDeadlineDropdown>
    </div>
  )
}
