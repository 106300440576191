import { API, type ApiTypes } from '@motion/rpc'
import {
  createQueryFilter,
  MODEL_CACHE_KEY,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import { cloneDeep } from '@motion/utils/core'
import { Sentry } from '@motion/web-base/sentry'
import type { FolderFolderItemSchema } from '@motion/zod/client'

import type { QueryClient } from '@tanstack/react-query'
// eslint-disable-next-line import-x/no-restricted-paths
import { findFolderItem } from '~/areas/folders/utils/find-folder-item'

type FolderData = ApiTypes<typeof API.folders.getFolders>['data']

export const folderQueryFilters = createQueryFilter([
  MODEL_CACHE_KEY,
  API.folders.queryKeys.getAll,
])

export async function applyOptimisticFolderItemUpdates(
  queryClient: QueryClient,
  itemId: FolderFolderItemSchema['id'],
  data: {
    order?: FolderFolderItemSchema['order']
    parentFolderId?: FolderFolderItemSchema['folderId']
  },
  type: 'ITEM' | 'PROJECT' = 'ITEM'
): Promise<OptimisticUpdateValue> {
  await queryClient.cancelQueries({
    queryKey: API.folders.queryKeys.getAll,
  })

  const { parentFolderId, order } = data

  // An object of data that will be provided to any errors sent to Sentry
  const errorDetail: Record<string, any> = {
    itemId,
    order,
    parentFolderId,
    type,
  }

  const originalData = queryClient.getQueryData<FolderData | undefined>(
    API.folders.queryKeys.getAll
  )

  if (!originalData?.models.systemFolders.workspaces) {
    return emptyRollback
  }

  const workspaces = cloneDeep(originalData.models.systemFolders.workspaces)

  const result = findFolderItem(workspaces, (item) => {
    if (type === 'PROJECT') return item.itemId === itemId

    return item.id === itemId
  })

  if (!result) {
    Sentry.captureException(new Error('Could not find item to move'), {
      extra: {
        ...errorDetail,
      },
      tags: {
        position: 'applyOptimisticFolderItemUpdates',
      },
    })

    return emptyRollback
  }

  const [item, parent] = result

  errorDetail.item = item
  errorDetail.parent = parent

  if (order) {
    item.order = order
  }

  if (parentFolderId && parent) {
    const itemIdx = parent.items.indexOf(item)

    if (itemIdx === -1) {
      Sentry.captureException(
        new Error('Could not get index of item in parent items'),
        {
          extra: {
            ...errorDetail,
          },
          tags: {
            position: 'applyOptimisticFolderItemUpdates',
          },
        }
      )

      return emptyRollback
    }

    // Remove from its original parent
    parent.items.splice(itemIdx, 1)

    const newParentResult =
      workspaces.id === parentFolderId
        ? [workspaces]
        : findFolderItem(workspaces, (item) => item.itemId === parentFolderId)

    if (!newParentResult) {
      Sentry.captureException(
        new Error('Could not find new parent folder item'),
        {
          extra: {
            ...errorDetail,
          },
          tags: {
            position: 'applyOptimisticFolderItemUpdates',
          },
        }
      )

      return emptyRollback
    }

    const [newParent] = newParentResult

    errorDetail.newParent = newParent

    if (!('items' in newParent)) {
      Sentry.captureException(new Error('New parent is not a folder'), {
        extra: {
          ...errorDetail,
        },
        tags: {
          position: 'applyOptimisticFolderItemUpdates',
        },
      })

      return emptyRollback
    }

    // Move to its new parent folder
    item.folderId = parentFolderId
    newParent.items.push(item)
  }

  const { rollback } = MotionCache.patch(
    queryClient,
    folderQueryFilters,
    'systemFolders',
    {
      workspaces,
    }
  )

  return {
    rollback,
    async withRollback<T>(p: Promise<T>) {
      try {
        return await p
      } catch (ex) {
        rollback()
        throw ex
      }
    },
  }
}

export async function applyOptimisticFolderItemDelete(
  queryClient: QueryClient,
  itemId: FolderFolderItemSchema['id'],
  type: 'ITEM' | 'PROJECT' = 'ITEM'
): Promise<OptimisticUpdateValue> {
  try {
    await queryClient.cancelQueries({
      queryKey: API.folders.queryKeys.getAll,
    })

    const originalData = queryClient.getQueryData<FolderData | undefined>(
      API.folders.queryKeys.getAll
    )

    if (!originalData?.models.systemFolders.workspaces) {
      return emptyRollback
    }

    const workspaces = cloneDeep(originalData.models.systemFolders.workspaces)

    const result = findFolderItem(workspaces, (item) => {
      if (type === 'PROJECT') return item.itemId === itemId

      return item.id === itemId
    })

    if (!result) {
      Sentry.captureException(new Error('Could not find item to delete'), {
        extra: {
          itemId,
          type,
        },
        tags: {
          position: 'applyOptimisticFolderItemUpdates',
        },
      })

      return emptyRollback
    }

    const [item, parent] = result

    parent.items.splice(parent.items.indexOf(item), 1)

    const { rollback } = MotionCache.patch(
      queryClient,
      folderQueryFilters,
      'systemFolders',
      {
        workspaces,
      }
    )

    return {
      rollback,
      async withRollback<T>(p: Promise<T>) {
        try {
          return await p
        } catch (ex) {
          rollback()
          throw ex
        }
      },
    }
  } catch (e) {
    Sentry.captureException(
      new Error('Could not apply optimistic folder item delete', { cause: e }),
      {
        extra: {
          itemId,
          type,
        },
        tags: {
          position: 'applyOptimisticFolderItemDelete',
        },
      }
    )

    return emptyRollback
  }
}

const emptyRollback = {
  rollback: () => void 0,
  async withRollback<T>(p: Promise<T>) {
    try {
      return await p
    } catch (ex) {
      throw ex
    }
  },
}
