import { FormModal } from '@motion/ui/base'
import {
  type ETADateOptions,
  type ETADateReturn,
  getPercentageTimeChange,
} from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema, type StageSchema } from '@motion/zod/client'

import { ProjectedDateOptions } from '~/areas/eta/components/common'
import { useEtaOptions } from '~/areas/eta/hooks'
import { type ModalTriggerComponentProps } from '~/areas/modals'
import { useProjectStagesWithDefinitions } from '~/areas/project/hooks/data'
import { ProjectBadge } from '~/global/components/badges'
import { DateTime } from 'luxon'

import {
  ProjectExtendedTimeline,
  ProjectStagesTimeline,
  ProjectStageUpdateDetails,
} from '../components'
import {
  BodyContainer,
  LeftSideContainer,
  RightSideContainer,
  TimelinesContainer,
  TimelineSectionContainer,
  TimelineSectionTitle,
} from '../styled'
import { resolveStageProjectedDate } from '../utils'

export type ReviewProjectDatesModalProps = {
  project: ProjectSchema
}

export function ReviewProjectDatesModal({
  close,
  project,
}: ModalTriggerComponentProps<'review-project-dates'>) {
  const {
    selectedDate: projectedEndDate,
    setSelectedOption,
    selectedOption,
    dateOptions,
  } = useEtaOptions({
    project,
  })

  return (
    <FormModal
      visible
      onClose={() => close(null)}
      submitAction={{
        onAction: () => {
          recordAnalyticsEvent('ETA_OPTIMIZE_MODAL_BUTTON_OPTIMIZE')
          close(projectedEndDate?.toISO() ?? null)
        },
        text: 'Extend deadline',
      }}
      cancelAction={{
        shortcut: 'esc',
      }}
      title={<ReviewProjectDatesHeader project={project} />}
      bodyPadded={false}
    >
      <ReviewProjectDatesModalBody
        project={project}
        projectedEndDate={projectedEndDate}
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        dateOptions={dateOptions}
      />
    </FormModal>
  )
}

function ReviewProjectDatesHeader({
  project,
}: {
  project: Pick<ProjectSchema, 'id' | 'name' | 'color' | 'statusId'>
}) {
  return (
    <div className='flex flex-row gap-3 items-center'>
      <span className='text-md text-semantic-neutral-text-default'>
        Extend deadline for
      </span>
      <div className='flex flex-row gap-1 items-center'>
        <ProjectBadge value={project} size='medium' hideTooltip />
        <span className='text-md text-semantic-neutral-text-default'>
          {project.name}
        </span>
      </div>
    </div>
  )
}

type ReviewProjectDatesModalBodyProps = {
  project: Pick<
    ProjectSchema,
    | 'startDate'
    | 'dueDate'
    | 'estimatedCompletionTime'
    | 'stages'
    | 'deadlineStatus'
  >
  projectedEndDate: DateTime | null
  setSelectedOption(option: ETADateOptions): void
  selectedOption: ETADateOptions
  dateOptions: ETADateReturn | null
}

function ReviewProjectDatesModalBody({
  project,
  projectedEndDate,
  setSelectedOption,
  selectedOption,
  dateOptions,
}: ReviewProjectDatesModalBodyProps) {
  if (
    project.startDate == null ||
    project.dueDate == null ||
    dateOptions == null ||
    projectedEndDate == null
  )
    return null

  const startDate = DateTime.fromISO(project.startDate)
  const dueDate = DateTime.fromISO(project.dueDate)

  const percentageTimeChange = getPercentageTimeChange({
    startDate,
    currentDueDate: dueDate,
    newDueDate: projectedEndDate,
    rounded: false,
  })

  return (
    <BodyContainer>
      <LeftSideContainer>
        <ETAOptionSelect
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          dateOptions={dateOptions}
        />
        <ExtendedTimelines
          startDate={startDate}
          dueDate={dueDate}
          projectedDate={projectedEndDate}
          stages={project.stages}
          percentageTimeChange={percentageTimeChange}
        />
      </LeftSideContainer>
      <RightSideContainer>
        <ProjectStageUpdateDetails
          project={project}
          projectedEndDateOverride={projectedEndDate}
        />
      </RightSideContainer>
    </BodyContainer>
  )
}

type ETADescriptionProps = {
  dateOptions: ETADateReturn
  selectedOption: ETADateOptions
  setSelectedOption: (option: ETADateOptions) => void
}

function ETAOptionSelect({
  dateOptions,
  selectedOption,
  setSelectedOption,
}: ETADescriptionProps) {
  return (
    <div className='flex flex-col gap-3'>
      <div className='text-semantic-neutral-text-default text-[13px] font-semibold leading-5'>
        Extend project deadline
      </div>
      <ProjectedDateOptions
        options={dateOptions}
        selectedOption={selectedOption}
        onChooseOption={setSelectedOption}
      />
    </div>
  )
}

type ExtendedTimelinesProps = {
  startDate: DateTime
  dueDate: DateTime
  projectedDate: DateTime
  stages: StageSchema[]
  percentageTimeChange: number
}

function ExtendedTimelines({
  startDate,
  dueDate,
  projectedDate,
  stages,
  percentageTimeChange,
}: ExtendedTimelinesProps) {
  const stagesWithDefinitions = useProjectStagesWithDefinitions(
    { stages },
    {
      includeCancelledStages: false,
    }
  )

  const currentStagesWithPositions = stagesWithDefinitions.map((stage) => ({
    positionDate: DateTime.fromISO(stage.dueDate),
    ...stage,
  }))

  const projectedStagesWithPositions = stagesWithDefinitions.map(
    (stage, index) => ({
      positionDate: DateTime.fromISO(
        resolveStageProjectedDate(stagesWithDefinitions, index)
      ),
      ...stage,
    })
  )

  const beforeWidthPercent = computeBeforeTimelineWidth(percentageTimeChange)

  return (
    <TimelinesContainer>
      <TimelineSectionContainer>
        <TimelineSectionTitle>Before</TimelineSectionTitle>
        <div
          style={{
            width: `${beforeWidthPercent}%`,
          }}
        >
          <ProjectStagesTimeline
            startDate={startDate}
            endDate={dueDate}
            stagesWithPositions={currentStagesWithPositions}
          />
        </div>
      </TimelineSectionContainer>
      <TimelineSectionContainer>
        <TimelineSectionTitle>After</TimelineSectionTitle>
        <div className='w-full'>
          <ProjectStagesTimeline
            startDate={startDate}
            endDate={projectedDate}
            stagesWithPositions={projectedStagesWithPositions}
            innerTimelineWidthPercent={beforeWidthPercent}
          />
          <ProjectExtendedTimeline
            percentageTimeChange={percentageTimeChange}
          />
        </div>
      </TimelineSectionContainer>
    </TimelinesContainer>
  )
}

function computeBeforeTimelineWidth(percentageTimeChange: number) {
  return 100 / (1 + percentageTimeChange / 100)
}
