import { Button, type ButtonProps } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'
import { type ProjectSchema } from '@motion/zod/client'

import { type ETA_SOURCE } from '~/@types/analytics'
import { useProjectDeadlineUpdater } from '~/areas/project/hooks'

import { useExtendedNoneEta } from '../../hooks'

type ReviewProjectDatesButtonProps = ButtonProps & {
  project: ProjectSchema
  source: ETA_SOURCE
}

export function ReviewProjectDatesButton({
  project,
  source,
  ...buttonProps
}: ReviewProjectDatesButtonProps) {
  const modalApi = useModalApi()
  const updateProjectDeadline = useProjectDeadlineUpdater()
  const deadlineStatus = useExtendedNoneEta({
    entity: project,
    type: 'project',
  })

  const handleOnClickReviewDates = async () => {
    recordAnalyticsEvent('ETA_OPEN_REVIEW_MODAL', {
      source,
      type: 'project',
      eta: deadlineStatus,
    })

    const response = await modalApi.prompt('review-project-dates', { project })

    if (response === ModalDismissed || response === null) return

    const newDeadline = response as string

    await updateProjectDeadline(project, newDeadline)
  }

  return (
    <Button {...buttonProps} onClick={handleOnClickReviewDates}>
      Review Dates
    </Button>
  )
}
