import { ActionList } from '@motion/ui/base'
import { normalizeStageDeadlineStatus } from '@motion/ui-logic'

import { type ActionOptions, useStageActions } from '~/areas/eta/hooks'
import { useMemo } from 'react'

import { type StageEtaPopoverContentProps } from './types'

import { SectionContainer } from '../styled'

export const StageEtaActions = ({
  project,
  stage,
  onClose,
  source,
}: StageEtaPopoverContentProps) => {
  const deadlineStatus = normalizeStageDeadlineStatus(stage, {
    normalizeToAtRisk: false,
  })

  const actionList = useMemo<ActionOptions[]>(() => {
    switch (deadlineStatus) {
      case 'none':
        return []
      case 'missed-deadline':
      case 'at-risk':
        return ['complete', 'cancel', 'edit']
      case 'scheduled-past-deadline':
        return ['moveToNextStage', 'complete', 'cancel', 'edit']
      case 'ahead-of-schedule':
        return ['edit']
      default:
        return []
    }
  }, [deadlineStatus])

  const stageActions = useStageActions(
    project,
    stage,
    actionList,
    onClose,
    source
  )

  if (stageActions.length === 0) {
    return null
  }

  return (
    <SectionContainer>
      <ActionList items={stageActions} />
    </SectionContainer>
  )
}
