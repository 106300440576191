import { Button } from '@motion/ui/base'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useHasTreatment } from '@motion/web-common/flags'
import { type TaskSchema } from '@motion/zod/client'

import { TaskEtaPopover } from '~/areas/eta/components/popover'
import { useExtendedNoneEta } from '~/areas/eta/hooks'
import { EtaBadge } from '~/global/components/badges'

import { useGetSentimentFromDeadlineStatus } from './hooks'
import { LabelContainer, LabelText, RibbonContainer } from './styled'

type TaskEtaRibbonProps = {
  task: TaskSchema
}

export function TaskEtaRibbon({ task }: TaskEtaRibbonProps) {
  const hasPastDueM2 = useHasTreatment('past-due-m2')
  const deadlineStatus = useExtendedNoneEta({ entity: task, type: 'task' })
  const [sentiment, label] = useGetSentimentFromDeadlineStatus(task)

  if (!hasPastDueM2) return null

  if (!sentiment || !label) return null

  const showResolveButton = sentiment === 'error' || sentiment === 'warning'

  return (
    <RibbonContainer sentiment={sentiment}>
      <EtaBadge value={deadlineStatus} size='normal' />
      <LabelContainer>
        <LabelText
          sentiment={
            sentiment === 'info' ||
            sentiment === 'completed' ||
            sentiment === 'cancelled'
              ? sentiment
              : 'none'
          }
        >
          {label}
        </LabelText>
        {showResolveButton && (
          <TaskEtaPopover
            entity={task}
            config={{ hideEditButton: true }}
            source='modal-banner'
          >
            <Button
              type='button'
              variant='link'
              onClick={() => {
                recordAnalyticsEvent('ETA_OPEN_POPOVER', {
                  source: 'modal-banner',
                  eta: deadlineStatus,
                  type: 'task',
                })
              }}
              sentiment='primary'
              size='small'
            >
              Resolve
            </Button>
          </TaskEtaPopover>
        )}
      </LabelContainer>
    </RibbonContainer>
  )
}
