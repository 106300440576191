import {
  type ProjectSchema,
  type StageSchema,
  type TaskSchema,
} from '@motion/rpc-types'
import {
  type ExtendedDeadlineStatus,
  getExtendedProjectDeadlineStatus,
  getExtendedStageDeadlineStatus,
  getExtendedTaskDeadlineStatus,
  getProjectEtaReason,
  getProjectEtaTitle,
  getProjectNoEtaReason,
  getStageEtaTitle,
  getStageNoEtaReason,
  getTaskEtaTitle,
  getTaskNoEtaReason,
} from '@motion/ui-logic'
import { useOnMountAnalyticsEvent } from '@motion/web-base/analytics'

import { type ETA_SOURCE } from '~/@types/analytics'
import { useI18N } from '~/global/contexts'
import { useWorkspaceFns } from '~/global/hooks'
import { type ReactNode, useMemo } from 'react'

import { useProjectStageToShow } from '../../hooks'

type EtaBadgeTooltipProps =
  | {
      entity: TaskSchema
      type: 'task'
    }
  | {
      entity: StageSchema
      type: 'stage'
    }
  | {
      entity: ProjectSchema
      type: 'project'
    }

const ACTION_RESOLUTION_TEXT = 'Click to see how to resolve this'

const DeadlineStatusResolutionText: Record<ExtendedDeadlineStatus, string> = {
  none: '',
  completed: '',
  cancelled: '',
  'on-track': 'Click to see more details',
  'ahead-of-schedule': 'Click to see how to optimize this',
  'at-risk': ACTION_RESOLUTION_TEXT,
  'missed-deadline': ACTION_RESOLUTION_TEXT,
  'scheduled-past-deadline': ACTION_RESOLUTION_TEXT,
}

const SHOULD_SHOW_NONE_ETA_REASON: ExtendedDeadlineStatus[] = [
  'none',
  'cancelled',
  'completed',
]

export const BadgeTooltip = ({
  entity,
  type,
  source,
}: EtaBadgeTooltipProps & { source: ETA_SOURCE }) => {
  const { pluralize } = useI18N()

  const stageToShow = useProjectStageToShow(type === 'project' ? entity : null)
  const { getWorkspaceStatuses } = useWorkspaceFns()

  const [bannerTitle, deadlineStatus] = useMemo<
    [string | ReactNode, ExtendedDeadlineStatus]
  >(() => {
    let deadlineStatus: ExtendedDeadlineStatus | undefined

    switch (type) {
      case 'task':
        deadlineStatus = getExtendedTaskDeadlineStatus(
          entity,
          getWorkspaceStatuses(entity.workspaceId)
        )

        if (SHOULD_SHOW_NONE_ETA_REASON.includes(deadlineStatus))
          return [
            getTaskNoEtaReason(
              entity,
              getWorkspaceStatuses(entity.workspaceId)
            ),
            deadlineStatus,
          ]
        return [getTaskEtaTitle(entity), deadlineStatus]
      case 'stage':
        deadlineStatus = getExtendedStageDeadlineStatus(entity)
        if (SHOULD_SHOW_NONE_ETA_REASON.includes(deadlineStatus))
          return [getStageNoEtaReason(entity), deadlineStatus]
        return [getStageEtaTitle(entity), deadlineStatus]
      case 'project':
        if (stageToShow) {
          deadlineStatus = getExtendedStageDeadlineStatus(stageToShow)
          return [getStageEtaTitle(stageToShow), deadlineStatus]
        }
        deadlineStatus = getExtendedProjectDeadlineStatus(
          entity,
          getWorkspaceStatuses(entity.workspaceId)
        )

        if (SHOULD_SHOW_NONE_ETA_REASON.includes(deadlineStatus))
          return [
            getProjectNoEtaReason(
              entity,
              getWorkspaceStatuses(entity.workspaceId)
            ),
            deadlineStatus,
          ]
        return [getProjectEtaTitle(entity, pluralize), deadlineStatus]
    }
  }, [type, entity, getWorkspaceStatuses, stageToShow, pluralize])

  useOnMountAnalyticsEvent('ETA_HOVER_BADGE', {
    properties: {
      source,
      type: type,
      eta: deadlineStatus,
    },
  })

  if (
    type === 'project' &&
    deadlineStatus === 'scheduled-past-deadline' &&
    entity.scheduledStatus === 'UNFIT_PAST_DUE'
  ) {
    return (
      <div className='flex flex-col'>
        <div className='text-sm font-medium'>{bannerTitle}</div>
        <div className='flex flex-col gap-2'>
          <span>{getProjectEtaReason(entity, pluralize)}</span>
          <span>Click to see the tasks that can't fit</span>
        </div>
      </div>
    )
  }

  const resolutionText = DeadlineStatusResolutionText[deadlineStatus]

  return (
    <div className='flex flex-col'>
      <div className='text-sm font-medium'>{bannerTitle}</div>
      {resolutionText && <span className='text-xs'>{resolutionText}</span>}
    </div>
  )
}
