import { CalendarPlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { AutoScheduleToggle, IconButton, Tooltip } from '@motion/ui/base'
import { isGhostTask } from '@motion/ui-logic/pm/project'
import {
  isAutoScheduledToggleEnabled,
  isMeetingTask,
} from '@motion/ui-logic/pm/task'
import { useHasTreatment } from '@motion/web-common/flags'

import { ConnectedTaskEtaBadge } from '~/areas/eta'
import { AssigneeDropdown } from '~/areas/project-management/components'
import { TaskStatusDropdown } from '~/areas/tasks/components'
import { AutoScheduleTooltip } from '~/areas/tasks/tooltips'
import { ConnectedAssigneeBadge, StatusBadge } from '~/global/components/badges'
import { useWorkspaceStatusById } from '~/global/hooks'
import { type NormalTaskWithRelations } from '~/global/proxies'

import { TaskName } from './task-name'

import {
  useAssigneeDropdown,
  useAutoSchedule,
  useStatusDropdown,
} from '../../../hooks'
import { SchedulingEventDropdown } from '../../events'
import { useSidebarTaskContext } from '../context'
import { TaskLineMenu } from '../task-line-menu'

type TaskPanelTaskLineProps = {
  className?: string
  task: NormalTaskWithRelations
}

export function TaskPanelTaskLine({ className, task }: TaskPanelTaskLineProps) {
  const { currentTaskId } = useSidebarTaskContext()
  const hasPastDueM2 = useHasTreatment('past-due-m2')

  const {
    onChange: onChangeStatus,
    value: statusDropdownValue,
    disabled: statusDisabled,
    iconVariant: statusIconVariant,
    taskVariant: statusTaskVariant,
    shouldExcludeResolved,
  } = useStatusDropdown(task)
  const {
    onChange: onChangeAssignee,
    removeUnassignedOption,
    selectedAssignee,
    disabled: assigneeDisabled,
  } = useAssigneeDropdown(task)
  const {
    isAutoScheduled,
    formattedScheduledDate,
    scheduledDateInfo,
    toggleChange: toggleChangeAutoSchedule,
    isError,
  } = useAutoSchedule(task)

  const status = useWorkspaceStatusById(task.statusId)

  const { assigneeUserId } = task

  const isGhost = isGhostTask(task)

  const scheduledDate =
    formattedScheduledDate ??
    (['unfit', 'unfitPastDue'].includes(scheduledDateInfo.type)
      ? scheduledDateInfo.shortText
      : undefined)

  const { disabled: isAutoScheduleDisabled } = isAutoScheduledToggleEnabled({
    status,
    isAutoScheduled,
  })

  const taskDateTextState =
    isError || (hasPastDueM2 && task.deadlineStatus === 'missed-deadline')
      ? 'error'
      : task.deadlineStatus === 'scheduled-past-deadline' && hasPastDueM2
        ? 'warning'
        : 'none'

  return (
    <TaskLineContainer className={className} isGhostTask={isGhost}>
      <TaskStatusDropdown
        isUnvisitedStage={isGhost}
        selectedStatusId={task.statusId}
        onChange={onChangeStatus}
        workspaceId={task.workspaceId}
        taskVariant={statusTaskVariant}
        excludeResolved={shouldExcludeResolved}
      >
        <button type='button' className='flex' disabled={statusDisabled}>
          <StatusBadge
            value={statusDropdownValue}
            size='small'
            iconVariant={statusIconVariant}
            taskVariant={statusTaskVariant}
          />
        </button>
      </TaskStatusDropdown>
      <TaskName currentTaskId={currentTaskId} task={task} />

      <SchedulingEventDropdown taskId={task.id}>
        <Tooltip asChild content='Schedule event'>
          <IconButton
            icon={CalendarPlusSolid}
            variant='muted'
            sentiment='primary'
            size='xsmall'
            aria-label='Schedule event'
          />
        </Tooltip>
      </SchedulingEventDropdown>

      {scheduledDate && (
        <>
          {(task.deadlineStatus === 'scheduled-past-deadline' ||
            task.deadlineStatus === 'missed-deadline') && (
            <ConnectedTaskEtaBadge
              task={task}
              size='xsmall'
              source='modal-list'
            />
          )}
          <Tooltip
            renderContent={() => (
              <AutoScheduleTooltip info={scheduledDateInfo} task={task} />
            )}
            asChild
          >
            <TaskDateText state={taskDateTextState}>
              {scheduledDate}
            </TaskDateText>
          </Tooltip>
        </>
      )}

      {!isMeetingTask(task) && (
        <AutoScheduleToggle
          checked={isAutoScheduled}
          onChange={toggleChangeAutoSchedule}
          labelHidden
          size='xsmall'
          disabled={isAutoScheduleDisabled}
        />
      )}

      <AssigneeDropdown
        onChange={onChangeAssignee}
        removeUnassignedOption={removeUnassignedOption}
        selectedUserId={selectedAssignee.userId}
        workspaceId={task.workspaceId}
      >
        <button type='button' disabled={assigneeDisabled}>
          <ConnectedAssigneeBadge
            userId={assigneeUserId ?? null}
            size='small'
          />
        </button>
      </AssigneeDropdown>

      <TaskLineMenu task={task} />
    </TaskLineContainer>
  )
}

const TaskDateText = classed('span', 'text-2xs', {
  variants: {
    state: {
      error: 'text-semantic-error-text-default',
      warning: 'text-semantic-warning-text-default',
      none: ' text-semantic-neutral-text-subtle text-nowrap',
    },
  },
  defaultVariants: {
    state: 'none',
  },
})

const TaskLineContainer = classed(
  'div',
  'group/task-line flex flex-row items-center grow gap-1.5',
  'truncate h-[30px] pl-4 pr-2',
  'relative',
  {
    variants: {
      isGhostTask: {
        true: 'opacity-50',
        false: '',
      },
    },
    defaultVariants: {
      isGhostTask: false,
    },
  }
)
