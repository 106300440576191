import { type CreateProjectDefinitionSchema } from '@motion/rpc-types'
import { findDefaultStatus } from '@motion/shared/common'
import { convertDaysToDeadlineInterval } from '@motion/shared/flows'

import { useWorkspaceStatuses } from '~/global/hooks'
import { useMemo } from 'react'

import { FLOW_TEMPLATE_PRESETS } from '../../presets'

export function usePresetTemplate(
  workspaceId: string | undefined,
  isCreatingFromPreset: boolean,
  fromPresetId: string | undefined
): CreateProjectDefinitionSchema | undefined {
  const workspaceStatuses = useWorkspaceStatuses(workspaceId ?? '')
  const defaultStatus =
    findDefaultStatus(workspaceStatuses) ?? workspaceStatuses[0]

  if (workspaceId == null) {
    throw new Error('Workspace id not defined')
  }

  return useMemo(() => {
    if (isCreatingFromPreset && fromPresetId != null) {
      const getTemplateFn = FLOW_TEMPLATE_PRESETS.find(
        (p) => p.id === fromPresetId
      )?.template
      const template = getTemplateFn?.()

      if (template == null) {
        throw new Error(`Preset with id ${fromPresetId} not found`)
      }

      return {
        ...template,
        workspaceId,
        stages: template.stages.map((stage) => ({
          ...stage,
          deadlineInterval: convertDaysToDeadlineInterval(
            stage.deadlineIntervalDays
          ),
          tasks: stage.tasks.map((task) => ({
            ...task,
            customFieldValuesFieldArray: [],
            statusId: defaultStatus.id,
          })),
        })),
      } satisfies CreateProjectDefinitionSchema
    }
  }, [isCreatingFromPreset, fromPresetId, workspaceId, defaultStatus.id])
}
