import { ActionList } from '@motion/ui/base'
import { normalizeProjectDeadlineStatus } from '@motion/ui-logic'

import { type ActionOptions, useProjectActions } from '~/areas/eta/hooks'
import { useMemo } from 'react'

import { type ProjectEtaPopoverContentProps } from './types'

import { SectionContainer } from '../styled'

export const ProjectEtaActions = ({
  project,
  onClose,
  config = {
    hideEditButton: false,
  },
  source,
}: ProjectEtaPopoverContentProps) => {
  const deadlineStatus = normalizeProjectDeadlineStatus(project)
  const actionList = useMemo<ActionOptions[]>(() => {
    let options: ActionOptions[] = []

    switch (deadlineStatus) {
      case 'none':
        options = []
        break
      case 'missed-deadline':
      case 'scheduled-past-deadline':
        options = ['complete', 'cancel', 'edit']
        break
      case 'ahead-of-schedule':
        options = ['edit']
        break
      default:
        options = []
        break
    }

    if (config.hideEditButton) {
      options = options.filter((option) => option !== 'edit')
    }

    return options
  }, [config.hideEditButton, deadlineStatus])

  const projectActions = useProjectActions(project, actionList, onClose, source)

  if (projectActions.length === 0) {
    return null
  }

  return (
    <SectionContainer>
      <ActionList items={projectActions} />
    </SectionContainer>
  )
}
